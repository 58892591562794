/**@Author Kumail Ali 

*/

import React from "react";
import { useState, useEffect } from "react";
import Header from "../components/Navbar/Header";
import Footer from "../components/Footer/Footer";
import moment from "moment";
import axios from "axios";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { logEvent, trackMetaEvent } from "../analytics/analytics";
import { MapContainer, TileLayer, Marker, useMap } from "react-leaflet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const {
  EMAIL,
  LOCATION,
  APP_URL,
  WHATSAPP_NUMBER,
  ELFA_DEALERS,
  AVAILABLE_SLOTS,
} = window["appconfig"];
const MySwal = withReactContent(Swal);
const markerIcon = new L.Icon({
  iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});
const TestDrive = () => {
  const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("03");
  const [location, setLocation] = useState("Gulistan-e-Johar");
  const [timeSlotList, setTimeSlot] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [timeslot, setSelectedTime] = useState("");
  const [sendingLocation, setSendingLocation] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isEnable, setIsEnable] = useState(false);
  // Example data structure to track booked time slots
  const [bookedSlots, setBookedSlots] = useState({});
  const [cnic, setCnic] = useState("");
  const [errors, setErrors] = useState({
    name: "",
    phone: "",
    cnic: "",
    date: "",
    time: "",
    location: "",
    address: "",
  });
  const RecenterMap = ({ center }) => {
    const map = useMap();
    map.setView(center, map.getZoom()); // Update map center without changing zoom
    return null;
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);

    // Regular expression for email format validation
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailRegex.test(emailValue) && emailValue !== "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Email format should be at xyz@example.com",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "",
      }));
    }
  };

  const handleLocationChange = (e) => {
    setLocation(e.target.value);

    const destination = `${ELFA_DEALERS[e.target.value].lat},${
      ELFA_DEALERS[e.target.value].lng
    }`;
    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${destination}`;

    setSendingLocation(googleMapsUrl);
  };
  const handleChangeAddress = (e) => {
    setAddress(e.target.value);
  };
  const handleNameChange = (e) => {
    const value = e.target.value.trimStart();
    setName(value);
    if (!/^[A-Za-z\s]{2,}$/.test(e.target.value)) {
      setErrors((prev) => ({
        ...prev,
        name: "Full Name should only contain letters and be at least 2 characters long.",
      }));
    } else {
      setErrors((prev) => ({ ...prev, name: "" }));
    }
  };

  const handleCnicChange = (e) => {
    let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters

    if (value.length > 5) {
      value = value.slice(0, 5) + "-" + value.slice(5);
    }
    if (value.length > 13) {
      value = value.slice(0, 13) + "-" + value.slice(13, 14);
    }

    setCnic(value); // Update the formatted CNIC

    // Validate CNIC format
    if (!/^\d{5}-\d{7}-\d{1}$/.test(value)) {
      setErrors((prev) => ({
        ...prev,
        cnic: "CNIC Number should be in the format XXXXX-XXXXXXX-X.",
      }));
    } else {
      setErrors((prev) => ({ ...prev, cnic: "" }));
    }
  };

  const handlePhoneChange = (e) => {
    let value = e.target.value.trim();

    // Ensure that the value starts with "03" and remove any non-numeric characters
    if (!value.startsWith("03")) {
      value = "03" + value.replace(/\D/g, ""); // Always prefix "03"
    } else {
      value = value.replace(/\D/g, ""); // Remove non-numeric characters if they exist
    }

    // Limit the value to 11 digits (after "03")
    if (value.length > 11) {
      value = value.slice(0, 11); // Limit to a maximum of 11 characters
    }

    setPhone(value); // Update the state with the formatted value

    // Validate the length to be exactly 11 digits (with "03" prefix)
    if (value.length !== 11) {
      setErrors((prev) => ({
        ...prev,
        phone: "Phone Number format should be 03XX-XXXXXXX",
      }));
    } else {
      setErrors((prev) => ({ ...prev, phone: "" }));
    }
  };

  const handleDateChange = (e) => {
    setSelectedTime("");

    setIsEnable(true);

    setSelectedDate(e.target.value);
  };
  const handleTimeChange = (e) => {
    const selectedTime = e.target.value;
    setSelectedTime(selectedTime);
  };

  const isDateDisabled = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");

    // Check if the date is booked or if the time slot is in the past or within one hour

    return bookedSlots[formattedDate]?.length >= timeSlotList.length;
  };

  // Check if a time slot is disabled for the selected date
  const isTimeSlotDisabled = (time) => {
    const now = new Date();

    // Parse the time slot into a Date object for the current day
    const [hourString, modifier] = time.split(" ");
    let hour = parseInt(hourString, 10);
    if (modifier === "PM" && hour !== 12) hour += 12;
    if (modifier === "AM" && hour === 12) hour = 0;

    const timeSlotDate = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      hour,
      0,
      0
    );
    // Check if the selected date is today
    const isToday = selectedDate === now.toISOString().split("T")[0];
    // Check if the time slot is in the past or less than 1 hour away
    const isPastOrWithinOneHour =
      isToday &&
      (timeSlotDate <= now || (timeSlotDate - now) / (1000 * 60) < 60);

    // Check if the time slot is booked for the selected date
    const isBooked = bookedSlots[selectedDate]?.includes(time);

    return isPastOrWithinOneHour || isBooked;
  };

  const formattedWhatsAppNumber = WHATSAPP_NUMBER.replace(/\D/g, "");
  useEffect(() => {
    // Async function inside useEffect
    const fetchData = async () => {
      // Declare the script inside the async function
      const script = document.createElement("script");
      script.src = `${process.env.PUBLIC_URL}/js/designesia.js`; // Use the correct path to your script
      script.defer = true;
      document.body.appendChild(script);

      try {
        // Corrected axios request
        const response = await axios.get(`${APP_URL}/api/time-slots`, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        const filteredTimeSlot = response?.data?.filter(
          (item) => item.value !== "10 AM"
        );
        setTimeSlot(filteredTimeSlot);

        // setTimeSlot(response.data);

        const booking_slots = await axios.get(
          `${APP_URL}/api/test-ride-lists`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const processedData = booking_slots.data.reduce((acc, response) => {
          // Parse the booking date
          const formattedDate = moment(
            response.demo_date,
            "MMMM Do YYYY"
          ).format("YYYY-MM-DD");
          const timeSlot = response.time_slot;

          // Initialize array for the date if not already present
          if (!acc[formattedDate]) {
            acc[formattedDate] = [];
          }

          // Add already booked time slots
          acc[formattedDate].push(timeSlot);

          return acc;
        }, {});

        // Add expired time slots to the processed data
        AVAILABLE_SLOTS.forEach((slot) => {
          const now = new Date();

          // Parse the time slot into a Date object
          const [hourString, modifier] = slot.split(" ");
          let hour = parseInt(hourString, 10);
          if (modifier === "PM" && hour !== 12) hour += 12;
          if (modifier === "AM" && hour === 12) hour = 0;

          const slotTime = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            hour,
            0,
            0
          );

          // Check if the time slot has expired
          const isExpired =
            slotTime <= now || (slotTime - now) / (1000 * 60) < 60;

          // Add expired slots to today's date
          const today = moment(now).format("YYYY-MM-DD");
          if (isExpired) {
            if (!processedData[today]) {
              processedData[today] = [];
            }
            if (!processedData[today].includes(slot)) {
              processedData[today].push(slot);
            }
          }
        });

        // Set the processed data to the state
        setBookedSlots(processedData);

        const destination = `${ELFA_DEALERS[location].lat},${ELFA_DEALERS[location].lng}`;
        const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${destination}`;

        setSendingLocation(googleMapsUrl);
      } catch (error) {
        console.error("Error fetching time zone data: ", error);
      }
    };

    fetchData(); // Call the async function

    // Cleanup function to remove the script when the component unmounts
    return () => {
      const script = document.querySelector(
        `script[src="${process.env.PUBLIC_URL}/js/designesia.js"]`
      );
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, []); // Empty dependency array to run only once on mount

  const handleGetDirection = (lat, lng) => {
    const destination = `${lat},${lng}`;
    window.open(
      `https://www.google.com/maps/dir/?api=1&destination=${destination}`,
      "_blank"
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent page reload

    setIsLoading(true);

    logEvent("test_drive_form_submit", {
      form_id: "test_drive_form",
      form_name: "TestDriveForm",
      form_destination: window.location.href,
      form_length: 7, // Adjust based on the number of fields
      form_submit_text: "Submit Test Drive Request",
    });

    // Log the event in Meta Pixel
    trackMetaEvent("TestDriveFormSubmit", {
      form_id: "test_drive_form",
      form_name: "TestDriveForm",
      form_destination: window.location.href,
      form_length: 7, // Adjust based on the number of fields
      form_submit_text: "Submit Test Drive Request",
      // Add other relevant parameters if needed
    });

    // Create form data object
    const formData = {
      name,
      phone,
      email,
      location,
      cnic,
      demoDate: moment(selectedDate).format("MMMM Do YYYY"), // Format date,
      timeslot,
      sendingLocation,
      address,
    };

    // Axios POST request
    try {
      const response = await axios.post(`${APP_URL}/api/test-drive`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        MySwal.fire({
          title: "Success!",
          html: response?.data?.message,
          icon: "success",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
        setName("");
        setEmail("");
        setPhone("");
        setCnic("");
        setSelectedDate("");
        setSelectedTime("");

        setIsLoading(false);
        //window.location.reload();
      } else {
        console.log("erorr if else");
        MySwal.fire({
          title: "Error!",
          text: response?.data?.message,
          icon: "error",
          confirmButtonText: "OK",
        });
        setSuccessMessage("");
        setIsLoading(false);
      }
    } catch (error) {
      MySwal.fire({
        title: "Error!",
        text: error?.response?.data?.message,
        icon: "error",
        confirmButtonText: "OK",
      });
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div id="wrapper">
        <div class="float-text show-on-scroll">
          <span>
            <a href="#">Scroll to top</a>
          </span>
        </div>
        <div class="scrollbar-v show-on-scroll"></div>
        <Header />\{" "}
        <div class="no-bottom no-top" id="content">
          <section class="pt10 pb10 mt50 bg-grey">
            <div class="container">
              <div class="row align-items-center">
                <div class="col-lg-10">
                  <h3 class="mb-0">
                    Book a Test Ride
                    <i class="icofont-dotted-right id-color ms-2"></i>
                  </h3>
                </div>

                <div class="col-lg-2">
                  <ul class="crumb">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li class="active">Book a Test Ride</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div class="container">
              <div class="row g-4 gx-5">
                <div class="col-lg-8">
                  <p>
                    Experience the thrill of riding our electric bikes
                    firsthand. Book your test ride today and feel the power and
                    innovation in every journey!
                  </p>

                  <form
                    name="contactForm"
                    id="contact_form"
                    class="position-relative z1000"
                    method="post"
                    action="#"
                    onSubmit={handleSubmit}
                  >
                    <div class="row gx-4">
                      <div class="col-lg-6 col-md-6 mb10">
                        <div class="field-set">
                          <span class="d-label fw-bold">
                            Full Name{" "}
                            <strong style={{ color: "red" }}>*</strong>
                          </span>
                          <input
                            type="text"
                            name="Name"
                            id="name"
                            class="form-control"
                            placeholder="Full Name"
                            required
                            value={name}
                            onChange={handleNameChange}
                          />
                          {errors.name && (
                            <p style={{ color: "red" }}>{errors.name}</p>
                          )}
                        </div>

                        <div class="field-set">
                          <span class="d-label fw-bold">
                            Phone Number{" "}
                            <strong style={{ color: "red" }}>*</strong>
                          </span>
                          <input
                            type="text"
                            name="phone"
                            id="phone"
                            class="form-control mb-4"
                            placeholder="Phone Number"
                            value={phone}
                            onChange={handlePhoneChange}
                            maxLength="11"
                            required
                          />
                          {errors.phone && (
                            <p style={{ color: "red" }}>{errors.phone}</p>
                          )}
                        </div>

                        {!mobile && (
                          <div class="field-set">
                            <span class="d-label fw-bold">
                              Select Date
                              <strong style={{ color: "red" }}>*</strong>
                            </span>
                            <input
                              type="date"
                              required
                              value={selectedDate}
                              onChange={handleDateChange}
                              min={today}
                              className="form-control"
                              onInput={(e) => {
                                let selected = e.target.value; // Store the selected date value

                                const day = new Date(selected).getUTCDay();
                                if ([6, 0].includes(day)) {
                                  e.preventDefault(); // Prevent the input from being selected
                                  e.target.value = ""; // Clear the invalid weekend date

                                  toast.error(
                                    "Demo rides are available Monday to Friday only. Please select a valid date",
                                    {
                                      position: "top-right",
                                      autoClose: 3000, // Closes after 3 seconds
                                      hideProgressBar: false,
                                      closeOnClick: true,
                                      pauseOnHover: true,
                                      draggable: true,
                                      progress: undefined,
                                      theme: "colored",
                                    }
                                  );
                                } else if (isDateDisabled(selected)) {
                                  e.target.value = ""; // Clear invalid date selection

                                  toast.error(
                                    "All slots are booked for this date.",
                                    {
                                      position: "top-right",
                                      autoClose: 3000, // Closes after 3 seconds
                                      hideProgressBar: false,
                                      closeOnClick: true,
                                      pauseOnHover: true,
                                      draggable: true,
                                      progress: undefined,
                                      theme: "colored",
                                    }
                                  );
                                }
                              }}
                            />

                            {errors.date && (
                              <p style={{ color: "red" }}>{errors.date}</p>
                            )}
                          </div>
                        )}
                        {!mobile && (
                          <div class="field-set">
                            <span class="d-label fw-bold">
                              Select Location{" "}
                              <strong style={{ color: "red" }}>*</strong>
                            </span>
                            <br />
                            <select
                              name="Location"
                              id="location"
                              required
                              style={{ width: "370px" }}
                              onChange={handleLocationChange}
                            >
                              {Object.keys(ELFA_DEALERS).map(
                                (location, index) => (
                                  <option
                                    key={`${location}-${index}`}
                                    value={location}
                                  >
                                    {location}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        )}
                        {!mobile && location !== "Other" && (
                          <MapContainer
                            center={ELFA_DEALERS[location]}
                            zoom={10}
                            style={{ height: "400px", width: "100%" }}
                          >
                            <TileLayer
                              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                              attribution={`&copy; <a href=${sendingLocation} target="_blank" >View location on Google Map</a>`}
                            />

                            <RecenterMap center={ELFA_DEALERS[location]} />
                            <Marker
                              position={ELFA_DEALERS[location]}
                              icon={markerIcon}
                              eventHandlers={{
                                click: () => {
                                  handleGetDirection(
                                    ELFA_DEALERS[location].lat,
                                    ELFA_DEALERS[location].lng
                                  );
                                },
                              }}
                            />
                          </MapContainer>
                        )}

                        {location == "Other" && (
                          <div class="field-set">
                            <span class="d-label fw-bold"></span>
                            Note: We provide test rides at specific locations.
                            Our representative will contact for Area/Location
                            verification.
                            <input
                              type="text"
                              name="Address"
                              id="address"
                              class="form-control"
                              placeholder="Address"
                              required
                              value={address}
                              onChange={handleChangeAddress}
                            />
                            {errors.address && (
                              <p style={{ color: "red" }}>{errors.address}</p>
                            )}
                          </div>
                        )}
                      </div>

                      <div class="col-lg-6 col-md-6">
                        <div class="field-set">
                          <span class="d-label fw-bold">
                            CNIC Number{" "}
                            <strong style={{ color: "red" }}>*</strong>
                          </span>
                          <input
                            type="text"
                            name="cnic"
                            id="cnic"
                            class="form-control"
                            placeholder="CNIC Number"
                            required
                            value={cnic}
                            maxLength={15}
                            onChange={handleCnicChange}
                          />
                          {errors.cnic && (
                            <p style={{ color: "red" }}>{errors.cnic}</p>
                          )}
                        </div>

                        <div class="field-set">
                          <span class="d-label fw-bold">Email Address</span>
                          <input
                            type="email"
                            name="Email"
                            id="email"
                            class="form-control"
                            placeholder="Email Address"
                            value={email}
                            onChange={handleEmailChange}
                          />
                        </div>
                        {errors.email && (
                          <p style={{ color: "red" }}>{errors.email}</p>
                        )}

                        {mobile && (
                          <div class="field-set">
                            <span class="d-label fw-bold">
                              Select Date
                              <strong style={{ color: "red" }}>*</strong>
                            </span>
                            <input
                              type="date"
                              required
                              value={selectedDate}
                              onChange={handleDateChange}
                              min={today}
                              className="form-control"
                              onInput={(e) => {
                                let selected = e.target.value; // Store the selected date value

                                const day = new Date(selected).getUTCDay();
                                if ([6, 0].includes(day)) {
                                  e.preventDefault(); // Prevent the input from being selected
                                  e.target.value = ""; // Clear the invalid weekend date

                                  toast.error(
                                    "Demo rides are available Monday to Friday only. Please select a valid date",
                                    {
                                      position: "top-right",
                                      autoClose: 3000, // Closes after 3 seconds
                                      hideProgressBar: false,
                                      closeOnClick: true,
                                      pauseOnHover: true,
                                      draggable: true,
                                      progress: undefined,
                                      theme: "colored",
                                    }
                                  );
                                } else if (isDateDisabled(selected)) {
                                  e.target.value = ""; // Clear invalid date selection

                                  toast.error(
                                    "All slots are booked for this date.",
                                    {
                                      position: "top-right",
                                      autoClose: 3000, // Closes after 3 seconds
                                      hideProgressBar: false,
                                      closeOnClick: true,
                                      pauseOnHover: true,
                                      draggable: true,
                                      progress: undefined,
                                      theme: "colored",
                                    }
                                  );
                                }
                              }}
                            />

                            {errors.date && (
                              <p style={{ color: "red" }}>{errors.date}</p>
                            )}
                          </div>
                        )}
                        {/* Time slots */}
                        <div class="field-set mb-3">
                          <span class="d-label fw-bold">
                            Select Time Slot{" "}
                            <strong style={{ color: "red" }}>*</strong>
                          </span>

                          <select
                            name="timeSlot"
                            id="timeSlot"
                            className="form-control"
                            required
                            value={timeslot}
                            disabled={!isEnable}
                            onChange={handleTimeChange}
                          >
                            <option value="">Select Time Slot</option>
                            {timeSlotList.map((time, index) => (
                              <option
                                key={index}
                                value={time.value}
                                disabled={isTimeSlotDisabled(time.value)}
                              >
                                {time.value}
                              </option>
                            ))}
                          </select>
                        </div>
                        {mobile && (
                          <div class="field-set">
                            <span class="d-label fw-bold">
                              Select Location{" "}
                              <strong style={{ color: "red" }}>*</strong>
                            </span>
                            <br />
                            <select
                              name="Location"
                              id="location"
                              required
                              className="form-control"
                              onChange={handleLocationChange}
                            >
                              {Object.keys(ELFA_DEALERS).map(
                                (location, index) => (
                                  <option
                                    key={`${location}-${index}`}
                                    value={location}
                                  >
                                    {location}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        )}
                        {mobile && location !== "Other" && (
                          <MapContainer
                            center={ELFA_DEALERS[location]}
                            zoom={10}
                            style={{ height: "400px", width: "100%" }}
                          >
                            <TileLayer
                              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                              attribution={`&copy; <a href=${sendingLocation} target="_blank" >View location on Google Map</a>`}
                            />

                            <RecenterMap center={ELFA_DEALERS[location]} />
                            <Marker
                              position={ELFA_DEALERS[location]}
                              icon={markerIcon}
                              eventHandlers={{
                                click: () => {
                                  handleGetDirection(
                                    ELFA_DEALERS[location].lat,
                                    ELFA_DEALERS[location].lng
                                  );
                                },
                              }}
                            />
                          </MapContainer>
                        )}
                      </div>
                    </div>

                    <div
                      class="g-recaptcha"
                      data-sitekey="copy-your-sitekey-here"
                    ></div>
                    <div id="submit" class="mt20">
                      <input
                        type="submit"
                        id="send_message"
                        value={isLoading ? "Loading..." : "Submit"}
                        class="btn-main"
                        disabled={isLoading}
                      />
                    </div>

                    {successMessage && (
                      <div id="success_message" class="success">
                        {successMessage}
                      </div>
                    )}
                    {errorMessage.length > 0 && (
                      <div id="error_message" class="error">
                        {errorMessage}
                      </div>
                    )}
                  </form>
                </div>

                <div class="col-lg-4">
                  <h4>Our Office</h4>
                  <div class="img-with-cap mb20">
                    <div class="d-title">Mon - Fri 10.00am - 6.30pm</div>
                    <div class="d-overlay"></div>
                    <img
                      src="images/misc/office.webp"
                      class="img-fullwidth rounded-1"
                      alt=""
                    />
                  </div>
                  <div class="spacer-single"></div>
                  <div class="fw-bold text-dark">
                    <i class="icofont-location-pin me-2 id-color-2"></i>Office
                    Location
                  </div>
                  <a
                    href="#"
                    onClick={() => handleGetDirection(24.799889, 67.10096)}
                  >
                    {LOCATION}
                  </a>
                  <div class="spacer-single"></div>
                  <div class="fw-bold text-dark">
                    <i class="icofont-whatsapp me-2 id-color-2"></i>WhatsApp
                    Support
                  </div>
                  <a
                    href={`https://wa.me/${formattedWhatsAppNumber}`}
                    target="_blank"
                  >
                    {WHATSAPP_NUMBER}
                  </a>
                  <div class="spacer-single"></div>
                  <div class="fw-bold text-dark">
                    <i class="icofont-envelope me-2 id-color-2"></i>Send a
                    Message
                  </div>
                  <a href="mailto:info@elfaelectric.com">{EMAIL}</a>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default TestDrive;
