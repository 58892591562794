// src/PageTracker.js
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  initializeAnalytics,
  logPageView,
  initializeMetaPixel,
  initializeTikTokPixel,
} from "./analytics";

const PageTracker = () => {
  const location = useLocation();

  useEffect(() => {
    initializeAnalytics();
    initializeMetaPixel();
    initializeTikTokPixel();
  }, []);

  useEffect(() => {
    const path = location.pathname.toLowerCase(); // Convert to lowercase
    console.log(path, "path---");

    // Exclude tracking for "/evsystem/" pages (case-insensitive)
    // if (!path.startsWith("/evsystem/")) {
    //   console.log("Logging page view for:", path);
    //   logPageView(path);
    // } else {
    //   console.log("GA tracking blocked for this route:", path);
    // }

    // if (typeof window.fbq !== "undefined") {
    //   window.fbq("track", "PageView", { page_path: location.pathname }); // Meta Pixel
    // }

    // ✅ Exclude tracking for "/evsystem/" pages across ALL platforms
    if (!path.startsWith("/evsystem/")) {
      console.log("✅ Logging page view for:", path);
      logPageView(path); // Google Analytics Tracking

      // ✅ Track Meta Pixel PageView
      if (typeof window.fbq !== "undefined") {
        window.fbq("track", "PageView", { page_path: location.pathname });
      }

      // ✅ Track TikTok Pixel PageView (Moved inside the condition)
      if (typeof window.ttq !== "undefined") {
        console.log("✅ Tracking TikTok PageView...");
        window.ttq.push([
          "track",
          "PageView",
          { page_path: location.pathname },
        ]);
      }
    } else {
      console.log("❌ Tracking blocked for this route:", path);
    }
  }, [location]);

  return null;
};

export default PageTracker;
