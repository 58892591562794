// src/analytics/analytics.js
import ReactGA from "react-ga4";
const { TRACKING_ID, META_PIXEL_ID, TIKTOK_PIXEL_ID } = window["appconfig"];

console.log(window["appconfig"].TIKTOK_PIXEL_ID, "tiktok id----------");
export const initializeAnalytics = () => {
  // console.log(TRACKING_ID, META_PIXEL_ID, "tracking id----");

  ReactGA.initialize(TRACKING_ID, { debug_mode: true }); // Replace with your actual GA4 tracking ID
};

export const logPageView = (path) => {
  // ReactGA.send({ hitType: "pageview", page: path });
  // Exclude tracking for "/Evsystem/" pages

  const normalizedPath = path.toLowerCase(); // Convert to lowercase
  console.log("logPageView called for:", normalizedPath); // Debugging

  // Exclude tracking for "/evsystem/" pages (case-insensitive)
  if (!normalizedPath.startsWith("/evsystem/")) {
    ReactGA.send({ hitType: "pageview", page: normalizedPath });
  } else {
    console.log("GA tracking blocked for this route:", normalizedPath);
  }
};

export const logEvent = (eventName, params) => {
  ReactGA.event(eventName, params);
};

// var fbq;
// Meta Pixel Initialization
export const initializeMetaPixel = () => {
  if (typeof window.fbq === "undefined" && META_PIXEL_ID) {
    (function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = "2.0";
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(
      window,
      document,
      "script",
      "https://connect.facebook.net/en_US/fbevents.js"
    );

    // Initialize Meta Pixel
    window.fbq("init", META_PIXEL_ID);
    window.fbq("track", "PageView");
  } else if (!META_PIXEL_ID) {
    console.warn("Meta Pixel ID is not set.");
  } else {
    console.warn("Meta Pixel is already initialized.");
  }
};

// Meta Pixel Event Logging
export const trackMetaEvent = (eventName, params = {}) => {
  if (typeof window.fbq !== "undefined") {
    window.fbq("track", eventName, params);
  } else {
    console.warn("Meta Pixel is not initialized or fbq is not defined.");
  }
};

// export const initializeTikTokPixel = () => {
//   if (!TIKTOK_PIXEL_ID) {
//     console.error("TikTok Pixel ID is missing!");
//     return;
//   }

//   if (typeof window.ttq === "undefined") {
//     (function (w, d, t, s, n) {
//       w[n] = w[n] || [];
//       var f = d.getElementsByTagName(t)[0],
//         j = d.createElement(t);
//       j.async = true;
//       j.src = s;
//       f.parentNode.insertBefore(j, f);
//     })(
//       window,
//       document,
//       "script",
//       "https://analytics.tiktok.com/i18n/pixel/sdk.js",
//       "ttq"
//     );

//     window.ttq = window.ttq || [];
//     window.ttq.push(["init", TIKTOK_PIXEL_ID]);
//     console.log("✅ TikTok Pixel Initialized with ID:", TIKTOK_PIXEL_ID);

//     setTimeout(() => {
//       window.ttq.push(["track", "PageView"]); // ✅ Ensure this fires after init
//       console.log("✅ TikTok PageView Event Tracked");
//     }, 2000); // ✅ Add a delay to ensure pixel loads
//   } else {
//     console.warn("TikTok Pixel is already initialized.");
//   }
// };

// ✅ TikTok Event Tracking

// ✅ Initialize TikTok Pixel
export const initializeTikTokPixel = () => {
  if (!TIKTOK_PIXEL_ID) {
    console.error("❌ TikTok Pixel ID is missing!");
    return;
  }

  if (typeof window.ttq !== "undefined") {
    console.warn("⚠ TikTok Pixel is already initialized.");
    return; // ✅ Prevents multiple initializations
  }

  (function (w, d, t, s, n) {
    w[n] = w[n] || [];
    var f = d.getElementsByTagName(t)[0],
      j = d.createElement(t);
    j.async = true;
    j.src = s;
    f.parentNode.insertBefore(j, f);
  })(
    window,
    document,
    "script",
    "https://analytics.tiktok.com/i18n/pixel/sdk.js",
    "ttq"
  );

  window.ttq = window.ttq || [];
  window.ttq.push(["init", TIKTOK_PIXEL_ID]);
  console.log("✅ TikTok Pixel Initialized with ID:", TIKTOK_PIXEL_ID);

  setTimeout(() => {
    window.ttq.push(["track", "PageView"]);
    console.log("✅ TikTok PageView Event Tracked");
  }, 2000);
};

// ✅ Track Custom TikTok Events
export const trackTikTokEvent = (eventName, params = {}) => {
  if (typeof window.ttq !== "undefined") {
    window.ttq.push(["track", eventName, params]);
    console.log(`✅ TikTok Event: ${eventName}`, params);
  } else {
    console.warn("❌ TikTok Pixel is not initialized.");
  }
};
